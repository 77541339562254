<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="500px" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon
                        small
                        class="mr-2"
                        v-on="{ ...tooltip, ...on }"
                        @click="getDataEstrella()"
                    >mdi-pencil</v-icon>
                </template>
                <span>Modificar</span>
            </v-tooltip>
        </template>
        <v-card :loading="loading">
            <v-card-title class="pa-0">
              <ModalTitle titulo="Modificar Iniciativa Ecolones" @cancelar="cancelar"/>
            </v-card-title>  
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            v-model="iniciativa.centroAcopio"
                            label="Nombre Centro de Acopio"
                            :error-messages="centroAcopioErrors"
                            @input="$v.iniciativa.centroAcopio.$touch()"
                            @blur="$v.iniciativa.centroAcopio.$touch()"
                            counter="100"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Tipo de Residuo"
                            v-model="iniciativa.tipoResiduo"
                            :error-messages="tipoResiduoErrors"             
                            @change="$v.iniciativa.tipoResiduo.$touch()"
                            @blur="$v.iniciativa.tipoResiduo.$touch()"
                            counter="100"
                            required>
                        </v-text-field>  
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Kilogramos Entregados"
                            v-model="iniciativa.kilos"
                            :error-messages="kilosErrors"             
                            @change="$v.iniciativa.kilos.$touch()"
                            @blur="$v.iniciativa.kilos.$touch()"
                            required>
                        </v-text-field>  
                    </v-col>
                    <v-col cols="12">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="fechaMoment"
                                    label="Fecha"
                                    prepend-inner-icon="mdi-calendar-month"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="fecha" 
                                locale="es-CR" 
                                @input="menu = false">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading" color="error" @click="cancelar">Cancelar</v-btn>
                <v-btn text :loading="loading" color="#0B3F67" @click="modificarEstrella">Modificar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment"
import axios from "@/axios";
import ModalTitle from '@/components/utils/ModalTitleComponent'
import { required, decimal, maxLength } from "vuelidate/lib/validators";

export default {
    created() {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.token;
    },
    components: {
      ModalTitle
    },
    computed: {
        fechaMoment () {
            return this.fecha ? moment(this.fecha).format('DD/MM/YYYY') : '';
        },
        idUsuario() {
            return this.$store.getters.usuario.id;
        },
        centroAcopioErrors() {
            const errors = []
            if (!this.$v.iniciativa.centroAcopio.$dirty) return errors            
            !this.$v.iniciativa.centroAcopio.required && errors.push('El nombre del centro de acopio es requerido.')
            !this.$v.iniciativa.centroAcopio.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        tipoResiduoErrors() {
            const errors = []
            if (!this.$v.iniciativa.tipoResiduo.$dirty) return errors            
            !this.$v.iniciativa.tipoResiduo.required && errors.push('El tipo de residuo es requerido.')
            !this.$v.iniciativa.tipoResiduo.maxLength && errors.push('No debe exceder el tamaño permitido.')
            return errors
        },
        kilosErrors() {
            const errors = []
            if (!this.$v.iniciativa.kilos.$dirty) return errors            
            !this.$v.iniciativa.kilos.required && errors.push('Los kilogramos entregados son requeridos.')
            !this.$v.iniciativa.kilos.decimal && errors.push('Solo debe digitar números.')
            return errors
        },
    },
    props: [
        'id',
    ],
    data: () => ({
        dialog: false,
        loading: false,
        menu: false,
        iniciativa: {
            centroAcopio: '',
            tipoResiduo: '',
            kilos: '',
        },
        fecha: new Date().toISOString().substr(0, 10),
    }),
    validations: {
        iniciativa: {
            centroAcopio: { required, maxLength: maxLength(100) },
            tipoResiduo: { required, maxLength: maxLength(100) },
            kilos: { required, decimal }
        },
    },
    methods: {
        dateFormat(fecha) {
            return new Date(fecha).toISOString().substr(0, 10);
        },
        cancelar() {
            this.iniciativa = {};
            this.fecha = new Date().toISOString().substr(0, 10);
            this.dialog = false;
            this.loading = false;
            this.$v.iniciativa.$reset();
        },
        getDataEstrella() {
            this.loading = true;
            axios.get("/estrellas/getIniciativaEcolon/"+this.id)
            .then(response => {
                this.iniciativa = response.data.iniciativa;   
                this.fecha = this.dateFormat(response.data.iniciativa.fecha);                    
                this.loading = false;
            }).catch(error => {
              if (error.request && error.request.status === 401) {
                this.$store.commit('showSessionDialog', true);
              } else {
                console.log(error);
                this.loading = false;
              }
            })
        },
        modificarEstrella() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;
                let iniciativa = this.iniciativa;
                delete iniciativa.docEvidencia;
                axios.put("/estrellas/modificarIniciativaEcolon", iniciativa, { params: {
                    idUsuario: this.idUsuario,
                    fecha: this.fecha,
                }}).then(response => {
                    this.$emit("recargaTabla");
                    this.$store.dispatch("noti", { mensaje: "Iniciativa modificada", color: "success" });
                    this.cancelar();
                }).catch(error => {
                  if (error.request && error.request.status === 401) {
                    this.$store.commit('showSessionDialog', true);
                  } else {
                    console.log("Error al modificar la iniciativa " + error);
                    this.$store.dispatch("noti", { 
                        mensaje: "Ocurrió un error no se modificó la iniciativa",
                        color: "error"
                    });
                    this.cancelar();
                  }
                });
            }
        }
    }
};
</script>
